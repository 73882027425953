<template>
  <v-container>
    <v-row>
      <v-col>
        <v-toolbar
          dense
          color="blue"
          dark
        >
          <h3>Perfil del prospecto</h3>
          <v-spacer />
          <v-btn
            dark
            color="orange"
            x-small
          >
            Procesar
          </v-btn>
        </v-toolbar>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          elevation="2"
          color="blue-grey lighten-5"
        >
          <v-row class="mx-1">
            <v-col>
              <h3><v-icon>mdi-store</v-icon>OPORTUNIDAD DE:</h3>
              <v-text-field
                v-model="nomcont"
                label="Contacto"
                readonly
                
                class="mb-1"
                hide-details
              />
              <v-text-field
                v-model="empresa"
                label="Empresa"
                readonly
                
                hide-details
              />

              <v-text-field
                v-model="asunto"
                label="Oportunidad"
                readonly
                hide-details
              />

              <!-- <leecontacto></leecontacto> -->
            </v-col>
          </v-row>
        </v-card>

        <v-row class="mt-4 mb-4">
          <v-col cols="12">
            <v-card
              class="rounded-lg"
              elevation="2"
              color="blue-grey lighten-4"
            >
              <v-row class="mx-1">
                <v-col>
                  <v-text-field
                    label="Giro"
                    hide-details
                  />
        
                  <v-text-field
                    label="Ubicacion"
                    hide-details
                  />

                  <v-text-field
                    label="¿Donde nos conoció?"
                    hide-details
                  />
        
                  <v-textarea
                    dense
                    outlined
                    auto-grow
                    rows="3"
                    row-height="15"
                    label="Personas involucradas"
                  />

                  <v-select
                    label="Tipo de contabilidad"
                    hide-details
                  />

                  <v-text-field
                    label="Equipo Punto de Venta"
                  />
  

                  <v-textarea
                    dense
                    outlined
                    auto-grow
                    rows="3"
                    row-height="15"
                    label="Problema a resolver"
                  />
                  <v-textarea
                    dense
                    outlined
                    auto-grow
                    rows="3"
                    row-height="15"
                    label="Sitacion actual"
                  />
                  <v-textarea
                    hide-details
                    rows="3"
                    dense
                    auto-grow
                    row-height="15"
                    label="¿Maneja Sucursales?"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import leecontacto from '@/components/leecontacto'
import {mapGetters,} from 'vuex'
export default {
  // components:{
  //   leecontacto
  // },

  data(){
    return{
      contacto:{},
      empresa:'',
      nomcont:'',
      asunto:''
    }
  },

  computed: {
    ...mapGetters('login',['getdatosUsuario']),
    ...mapGetters('oportunidad',['getOportunidad']),
  },
  
  watch:{
    getOportunidad(){
      // this.limpiar()
      this.actualizarOportunidad()
    },
  },

  methods:{

    actualizarOportunidad(){
      //Mandar llamar a getoportunidad para cargar los datos de la oportunidad seleccionada
      this.getOportunidad
      console.log(this.getOportunidad)

      this.obs = this.getOportunidad.obs
      this.asunto = this.getOportunidad.asunto
      this.nomcont = this.getOportunidad.nomcont
      this.empresa = this.getOportunidad.empresa
    },


    validate () {
      return this.$refs.form.validate()
    },


    CargarInfo(cita){
      this.empresa  = cita.empresa
      this.obs      = 	cita.obs
      this.nomcont  =	cita.nomcont
    },
  },
}
</script>

<style>

</style>